import React, {useEffect, useState} from "react";
import { useParams, useNavigate } from "react-router-dom";
import httpClient from '../../config/httpClient';
import { BaseApi } from "../../config";

export const UnscribeEmail = () => {
    const navigate = useNavigate();
    const params = useParams();
    useEffect(() => {
        unscribeMail()
    },[])
    const unscribeMail = async () => {
        const response = await httpClient.post(`${BaseApi}/unscribe_email/`, {token: params.link})
        navigate("/")
    }   
    return (
        <>

        </>
    )
}

export default UnscribeEmail