import React, { useState } from "react";
import { Tab, Tabs, Card } from "react-bootstrap";
import UserProfile from "../../components/UserProfile";
import CompanyProfile from "../../components/CompanyProfile";
import { getBillingDetails } from "../../api/billingApi";
import Skeleton from "react-loading-skeleton";

export const UserPage = () => {
    const [selectedKey, setSelectedKey] = useState();

    const handleSelect = async (key) => {
      setSelectedKey(key);
      if (key === "billing") {
        const resp = await getBillingDetails();
        // window.open(resp.url, "_blank");
        window.location.href=resp.url
        setSelectedKey("user")
      }
    }
  
    return (
      <div className="container py-3">
        <Card>
          <Card.Body>
            <h1 className="h1">Edit Profile</h1>
            <Tabs 
               id="controlled-tab-example"
               defaultActiveKey={"user"}
               onSelect={handleSelect}
               className="mb-3"
              >
              <Tab eventKey="user" title="user">
                  <UserProfile />
              </Tab>
              <Tab eventKey="company" title="company">
                  <CompanyProfile /> 
              </Tab>
              <Tab eventKey="billing" title="billing">
                <Skeleton count={7} height="60px" />
              </Tab>
          </Tabs>
      </Card.Body>
  </Card>
  </div>
  )
}

export default UserPage;