import React, {useState, useEffect} from "react"
import { Table, Button } from "react-bootstrap"
import { MDBIcon } from "mdb-react-ui-kit"
import LeadsIcon from "../LeadsIcons"
import LeadsLoader from "../LeadsLoader"
import { sortOptions } from "../../helpers/constantData"

export const MyLeadsTable = ({leads, loadingLeads, handleDetails, sort, setSort}) => {
    
    const handleSort = (sortType) => {
        if (sort === sortOptions[sortType].asc) setSort(sortOptions[sortType].desc)
        else setSort(sortOptions[sortType].asc)    
    }


    return <>
        {loadingLeads? 
                            (
                               <LeadsLoader />
                            )
                            :
                            (   
                            <>                        
                                <Table hover className='appTable  col-lg-9 col-my-leads' style={{position: 'relative'}}>
                                <thead style={{backgroundColor: "white"}}>
                                    <tr >
                                        <th style={{ position: "sticky", top: '-2px', backgroundColor: "white"}}>Icon</th>
                                        <th style={{ position: "sticky", top: '-2px', backgroundColor: "white"}}>Service Type</th>
                                        <th className='d-none d-md-table-cell text-center ' style={{ position: "sticky", top: '-2px', backgroundColor: "white"}}>
                                            <div className="d-flex align-items-center gap-1" >
                                                <span>Days To Disposition</span> 
                                                <span   
                                                    onClick={() => handleSort("age")}
                                                    style={{cursor: 'pointer'}} > 
                                                    <MDBIcon fas icon={sort == sortOptions.age.asc || sort == sortOptions.age.desc ? sort == sortOptions.age.asc? "sort-up": "sort-down" : "sort-down"} />
                                                </span>
                                            </div>
                                        </th>
                                        <th style={{ position: "sticky", top: '-2px', backgroundColor: "white"}}>Distance</th>
                                        <th className='d-none d-md-table-cell text-center ' style={{ position: "sticky", top: '-2px', backgroundColor: "white"}}>City</th>
                                        <th className='d-none d-md-table-cell text-center ' style={{ position: "sticky", top: '-2px', backgroundColor: "white"}}>State</th>
                                        <th className='d-none d-md-table-cell text-center ' style={{ position: "sticky", top: '-2px', backgroundColor: "white"}}>Zipcode</th>
                                        {/*<th className='d-table-cell d-md-none text-center ' style={{ position: "sticky", top: '-2px', backgroundColor: "white"}}>Location</th> */}
                                        <th style={{position: "sticky", top: '-2px', backgroundColor: "white", color: "white"}}>Show</th>

                                    </tr>
                                
                                </thead>
                                <tbody>
                                    {leads.length > 0 ? leads.map((lead) => (
                                    <tr key={ lead.leadID } style={{backgroundColor: lead.disable? "#E6E8E8": "", height: "84px"}}>
                                        
                                        <td className='px-1 text-center' style={{borderBottom: 0, height: "84px"}}>
                                            <div className='d-flex justify-content-center align-items-center' style={{height: "84px"}}>

                                                <LeadsIcon icon={lead.iconName} width="50px" height="50px" />
                                            </div>
                                        </td>
                                        <td className='px-1 text-center' style={{borderBottom: 0, height: "84px"}}>
                                            <div className='d-flex justify-content-center align-items-center' style={{height: "84px"}}>
                                            {lead.VerticalName}

                                            </div>
                                        </td>
                                        <td style={{borderBottom: 0, height: "84px"}} className='px-1 d-none d-md-table-cell text-center ' >
                                            <div className='d-flex justify-content-center align-items-center' style={{height: "84px"}}>
                                                {lead.leadDate} Days
                                            </div>

                                        </td>
                                        <td style={{borderBottom: 0, height: "84px"}} className='px-1 d-none d-md-table-cell text-center ' >
                                            <div className='d-flex justify-content-center align-items-center' style={{height: "84px"}}>
                                                {`${parseInt(lead.Distance)} mi`}
                                            </div>
                                        </td>
                                        <td style={{borderBottom: 0, height: "84px"}} className='d-none d-md-table-cell text-center '>
                                            <div className='d-flex justify-content-center align-items-center' style={{height: "84px"}}>
                                                {lead.city}
                                            </div>
                                        </td>
                                        <td style={{borderBottom: 0, height: "84px"}} className='d-none d-md-table-cell text-center '>
                                            <div className='d-flex justify-content-center align-items-center' style={{height: "84px"}}>
                                                {lead.usState}
                                            </div>
                                        </td>
                                        <td style={{borderBottom: 0, height: "84px"}} className='d-none d-md-table-cell text-center '>
                                            <div className='d-flex justify-content-center align-items-center' style={{height: "84px"}}>
                                                {lead.zipcode}
                                            </div>
                                        </td>
                                        {/*<td style={{borderBottom: 0, height: "84px"}} className='d-table-cell d-md-none text-center '>
                                            <div className='d-flex justify-content-center align-items-center' style={{height: "84px"}}>
                                                {`${lead.city}-${lead.usState}-${lead.zipcode}`}
                                            </div>
                                        </td>*/}
                                        <td className='px-3 text-center ' style={{paddingBlock: "0.83rem", borderBottom: 0, height: "84px"}}>
                                            <div className='d-flex justify-content-center align-items-center' style={{height: "84px"}}>
                                                <Button 
                                                    variant="dark" 
                                                    onClick={() => handleDetails(lead)}
                                                    size="sm"
                                                    >
                                                        Details
                                                </Button>

                                            </div>
                                        </td>
                                    </tr>
                                    )) : null}
                                </tbody>                        
                                </Table>
                                </>
                            )}
    </>
}

export default MyLeadsTable;