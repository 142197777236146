import React,{ useEffect, useState, useRef } from "react"

function clickOutside(ref, onClickOutside) {
    useEffect(() => {
      /**
       * Invoke Function onClick outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          onClickOutside();
        }
      }
      // Bind
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // dispose
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, onClickOutside]);
  }

export const Multiselect = ({ value, onChange, options = [], filter, displayMessage = 'Service Type', height='' }) => {
    
    const [displayValues, setDisplayValues] = useState(`Select a ${displayMessage}`)
    const [open, setOpen] = useState(false)
    const [selectOptions, setSelectOptions] = useState([])
    const [search, setSearch] = useState('')
    const dropdown = useRef(null)

    const handleDropItem = (option) => {
        const valueAuxiliar = value.filter((val) => val.value == option.value)
        if (valueAuxiliar.length) onChange(value.filter((val) => val.value !== option.value))
        else onChange([...value, option])
    }
    
    clickOutside(dropdown, () => {
        setOpen(false)
    })

    useEffect(() => {
        if (value.length) {
            const message = value.length == 1? `${value.length} ${displayMessage} Selected` : `${value.length} ${displayMessage}s Selected`
            setDisplayValues(message)
        } else {
            setDisplayValues(`Select a ${displayMessage}`)
        }
    }, [value])

    const handleSearch = (e) => {
        if (!e.target.value) setSelectOptions(options)
        else {
            const newOptionsArray = options.filter(option => option.label.toLowerCase().includes(e.target.value.toLowerCase()))
            setSelectOptions(newOptionsArray)
        }
    }

    useEffect(()=> {
        if (options.length) setSelectOptions(options)
    }, [options])


    return (
        <div 
             style={{position: 'relative'}}
             ref={dropdown}
        >
            <button 
                type='button'
                className="form-control d-flex align-items-center" 
                style={{width: '100%',border: '1px solid #bdbdbd', height}}
                onClick={() => {
                    setOpen(prev => !prev);
                }}
            >
                <span className='me-auto'>{displayValues}</span>
                <span style={{fontSize: '1.25rem'}}>{open? <i className="fas fa-caret-up"></i> : <i className="fas fa-caret-down"></i>}</span>
            </button>
            <div 
                style={{
                    width: '100%', 
                    position: 'absolute',
                    zIndex: open? 10 : -1,
                    height: '200px',
                    overflowY: 'auto',
                    borderRadius: "0.25rem",
                    borderBottom: open? "1px solid rgba(0,0,0,.125)" : "",
                
                }}
            >
            <ul className={`list-group ${open? '' : 'd-none'}`} >
                
                {
                    filter?
                    <li className="p-2 d-flex justify-content-center input-group" style={{backgroundColor: 'white'}}>
                        <input 
                            className="form-control col-6"
                            onChange={handleSearch}    
                        />
                        <span className="input-group-text"><i className="fas fa-search"></i></span>
                    </li>
                    :
                    null
                }
                {
                    selectOptions.length?
                        selectOptions.map((option) => (
                            <li 
                                key={option.value}
                                onClick={() => handleDropItem(option)} 
                                className='list-group-item list-group-item-action d-flex gap-2'
                                style={{cursor: 'pointer'}}
                            >
                                <input checked={value.filter(val => val.value == option.value).length? true: false} className="form-check-input ms-1" type="checkbox"/>
                                <span>{option.label}</span>
                            </li>   
                        ))
                    :
                    <li className='list-group-item'>
                        No options available
                    </li>
                }
            </ul>
            </div>
        </div>
    )
}