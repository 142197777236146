import React, {useState} from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Multiselect } from "../Multiselect";
import { inputRange } from "../../helpers/inputHelpers";
import Skeleton from "react-loading-skeleton";
import { Modal } from "react-bootstrap";
import { MDBInput, MDBBtn } from "mdb-react-ui-kit";
import { setRequestServiceType } from "../../api/leadsApi";
export const FilterLeadBoard = ({
    radius, 
    setRadius, 
    zipcode, 
    setZipcode, 
    selectVertical, 
    setSelectVerticals, 
    perimeter, 
    verticalGroupOptions,
    isLogged,
    getLeadsData,
    setLeadBoardUnLogged,
    handleAddress
}) => {
    const [show,setShow] = useState(false)
    const [serviceRequest, setService] = useState('')
    const [loading, setLoading] = useState(false)
    const handleZipcode = (e) => {
        if (e.target.value.length > 5 ) return;
        setZipcode(e.target.value);
        
    }

    const handleRadius = (rad) => {
        if (parseInt(rad) <= 0) return setRadius(1);
        setRadius(inputRange(rad, 0, 1000));
    }

    const handleVerticalSelect = (vert) => {
        setSelectVerticals(vert);
        localStorage.setItem("verticals",vert.map(v => v.value).join(","))
    }

    const handleSubmit = async () => {
        setLoading(true)
        try {
            await setRequestServiceType({
                surveyOption: 'service_type_request',
                surveyOptionDescription: serviceRequest
            })
            setShow(false)
            setLoading(false)
        } catch (e) {
            console.log(e)
            setShow(false)
            setLoading(false)
        }
    }


    const handleSearch = () => {
        if(zipcode.length <5) return alert("Invalid zipcode")
        isLogged? getLeadsData() : setLeadBoardUnLogged();
        handleAddress();
        localStorage.setItem("zip", zipcode)
        localStorage.setItem('radius', radius)
    }

    return <Form>
        <Row className='SearchBox'>
            <Col lg={2} className="my-lg-auto mt-lg-auto my-1 mt-3 py-2 d-flex justify-content-center py-md-0"> 
                
                {
                    perimeter?  

                        (
                            <div className='input-group'>
                                <input value={zipcode} onChange={handleZipcode} className='form-control' />
                                <span  class="input-group-text">Zipcode</span>
                            </div>
                        )   
                        :
                        <Skeleton height={50} width={"200px"}/>
                }
            
            </Col>
            <Col lg={2} className='my-lg-auto my-1 py-2 d-flex justify-content-center py-md-0'>
            { 
                perimeter?
                <div className='input-group'>
                    <input className='form-control' min="0" max="100" type="number" value={radius} onChange={e => handleRadius(e.target.value)} />
                    <span class="input-group-text">Radius</span>
                </div>
                :
                <Skeleton height={50} width={"200px"}/>
                }
            </Col>
            <Col xl={7} lg={6} className="my-lg-auto my-1 py-3 py-xl-0">
                {
                    perimeter?
                    <div className="d-flex flex-column">
                    <Multiselect height='5vh' value={selectVertical} options={verticalGroupOptions} onChange={handleVerticalSelect} />
                    <span style={{cursor:'pointer', fontSize:'11px', height: '0.75vh'}} onClick={() => setShow(true)} className="text-decoration-underline">Don't see your service type here?</span>
                    </div>
                    :
                    <Skeleton height={50} width={"200px"}/>
                }
                
            </Col>
            <Col xl={1} lg={2} className="my-lg-auto px-1 ms-2 mb-2 ms-lg-0 mb-lg-auto">
                <Button variant='dark' onClick={handleSearch}>
                    Search
                </Button>
            </Col>
        </Row>
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton>
               <span className="h4"> Service Type Request</span>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column">
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                    <span>
                        Please submit one request
                    </span>
                    <span>
                        per service type you would
                    </span>
                    <span>
                        like added
                    </span>
                    </div>
                    <label className='form-label'>
                        Service Type Name:
                    </label>
                    <input 
                        className='form-control mb-2'
                        onChange={(e) => setService(e.target.value) }
                    />
                    <MDBBtn disabled={loading} onClick={() => handleSubmit()} color="dark" style={{alignSelf:'center', width: '50%'}}>
                        Submit
                    </MDBBtn>
                </div>
            </Modal.Body>
        </Modal>
    </Form>
}

export default FilterLeadBoard;