import React from "react"
import { MDBInput } from "mdb-react-ui-kit"

export const ValidateInput = ({value, onChange, invalid, label, feedback, type = "text", min, max}) => {
    
    const handleChange = (e) => {
        onChange(e.target.value);
    }

    return (
        <>
            {
                invalid?
                <form className="needs-validation was-validated">
                <div>
                  <div className='form-outline'>
                    <input 
                      id={label}
                      className='form-control active form-control-lg' 
                      style={{border: "1.5px solid #dc3545"}}
                      value={value}
                      onChange={handleChange}
                      type={type}
                      min={min}
                      max={max}z
                    />
                    <label className="form-label" for={label} style={{color:"#dc3545", backgroundColor: "white", paddingInline: "4px"}}>{label}</label>
                    <div style={{ display: 'block', position: 'relative', zIndex: 2}} className="invalid-feedback">
                      {feedback}
                    </div>

                  </div>
                </div>

                </form>
                :
                <MDBInput value={value} wrapperClass='mb-4'  label={label} id={label} type={type} size="lg" onChange={handleChange} min={min} max={max}/>
            }
        </>
    )
}

export default ValidateInput;