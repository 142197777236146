import httpClient from "../config/httpClient";
import { BaseApi } from "../config";
export const setLeadClaim = async (body) => {
    try {
        const response = await httpClient.post(`${BaseApi}/leads/claim/`, body)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error setting the claim"}
    }
}

export const getLeadsClaim = async (transactionType) => {
    try {
        const response = await httpClient.get(`${BaseApi}/leads/claim/?transactionType=${transactionType}`)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error getting the claims"}
    }
}

export const updateLeadClaim = async (body) => {
    try {
        const response = await httpClient.put(`${BaseApi}/leads/claim/`, body)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error updating the claim"}
    }
}

export const getLeadProperties = async (body) => {
    try {
        const response = await httpClient.post(`${BaseApi}/leads/data/properties/`, body)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error getting the properties"}
    }
}

export const updateLeadStatusView = async (body) => {
    try {
        const response = await httpClient.put(`${BaseApi}/leads/data/view/`, body)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error updating the status"}
    }
}

export const getLead = async (leadID) => {
    try {
        const response = await httpClient.get(`${BaseApi}/leads/data/detail/?leadID=${leadID}`)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error getting the lead"}
    }
}

export const getLeadBoardDetail = async (leadID) => {
    try {
        const response = await httpClient.get(`${BaseApi}/leads/leadboard/detail/?leadID=${leadID}`)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error getting the lead Detail"}
    }
}

export const getLeadBoardPublicDetail = async (leadID) => {
    try {
        const response = await httpClient.get(`${BaseApi}/public/leads/leadboard/detail/?leadID=${leadID}`)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error getting the lead Detail"}
    }
}

export const getMyLeadDetail = async (leadID) => {
    try {
        const response = await httpClient.get(`${BaseApi}/leads/data/?leadID=${leadID}`)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error getting the lead Detail"}
    }
}



export const getLeadsMyLeads = async( zipcode) => {
    try {
        const response = await httpClient.get(`${BaseApi}/leads/myleads/?zipcode=${zipcode}`)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error getting the leads "}
    }
};


export const getClosedLeadDetail = async(leadID) => {
    try {
        const response = await httpClient.get(`${BaseApi}/leads/closedleads/detail/?leadID=${leadID}`)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error getting the leads "}
    }
};

export const getClosedLeads = async(zipcode) => {
    try {
        const response = await httpClient.get(`${BaseApi}/leads/closedleads/?zipcode=${zipcode}`)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error getting the leads "}
    }
};

export const getPublicLeads = async (body) => {
    try {
        const response = await httpClient.post(`${BaseApi}/public/leads/data/`, body)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error getting the leads "}
    }
}

export const setRequestServiceType= async (body) => {
    try {
        const response = await httpClient.post(`${BaseApi}/leads/service_type_request/`, body)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error getting the leads "}
    }
}
export const getReminderLeadIDToken= async (body) => {
    try {
        const response = await httpClient.post(`${BaseApi}/leads/mylead_reminder/`, body)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error getting the leads "}
    }
}

export const getLeadsClaimLimit = async () => {
    try {
        const response = await httpClient.get(`${BaseApi}/leads/leadsclaim/count/`)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error getting the leads "}
    }
}

export const getLeadsCsv = async (type) => {
    try {
        const response = await httpClient.get(`${BaseApi}/leads/downloadcsv/?type=${type}`)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error getting the leads "}
    }
}


