import React from "react"
import Logo  from "../../assets/images/logo.svg"
import "./loader.css"

export const LeadsLoader = () => {
    return (
        <div className="leads-loader-container">

            <div><img src={Logo} width="270px" height="270px"/></div>
            <div className="d-flex align-items-center flex-row" width = "100">
                <div class="dot1"></div>
                <div class="dot2"></div>
                <div class="dot3"></div>
            </div>
        </div>
    )
}

export default LeadsLoader;