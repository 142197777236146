import httpClient from "../config/httpClient";
import { BaseApi } from "../config";

export const getBillingDetails = async () => {
    try {
        const resp = await httpClient.get(`${BaseApi}/payment/manage/`)
        return resp.data;
    } catch (e) {
        console.error(e)
    }
    return null
} 

export const authorizePayment = async (body) => {
    try {
        const response = await httpClient.post(`${BaseApi}/payment/authorize_card/`, body)
        return response.data;
    } catch (e) {
        console.error(e)
        return { error : "Error updating the claim"}
    }
}