import React from "react";
import { Table, Button } from "react-bootstrap";
import { MDBIcon, MDBBtn } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import { sortOptions } from "../../helpers/constantData";
import LeadsIcon from "../LeadsIcons";
import styles from "./leadBoardTable.module.css"

export const LeadBoardTable = ({leads, verticals, handleDetails, setLeads, sort, setSort}) => {
    
    const navigate = useNavigate()

    const handleSort = (sortType) => {
        if (sort === sortOptions[sortType].asc) setSort(sortOptions[sortType].desc)
        else setSort(sortOptions[sortType].asc)    
    }

     const getVertical = (id) => {
        const vertical = verticals.filter( vertical => vertical.verticalID == id);
        if(vertical.length) return vertical[0].verticalName;
        return ''
    }


    return (
    <Table hover className='appTable' style={{position: 'relative'}}>
        <thead style={{backgroundColor: "white"}}>
            <tr>
                <th style={{ position: "sticky", top: '-2px', backgroundColor: "white"}}>Icon</th>
                <th className='text-center' style={{ position: "sticky", top: '-2px', backgroundColor: "white"}}>Service Type</th>
                <th className='d-none d-md-table-cell text-center '  style={{ position: "sticky", top: '-2px', backgroundColor: "white"}}>
                    <div className="d-flex align-items-center gap-1 justify-content-center">
                        Lead Age 
                        <span   
                            onClick={() => handleSort("age")}
                            style={{cursor: 'pointer'}}
                        > 
                            <MDBIcon fas icon={sort == sortOptions.age.asc || sort == sortOptions.age.desc ? sort == sortOptions.age.asc? "sort-up": "sort-down" : "sort-down"} />
                        </span>

                    </div>
                </th>
                <th className='d-none d-md-table-cell text-center ' style={{ position: "sticky", top: '-2px', backgroundColor: "white"}}>
                    <div className="d-flex align-items-center gap-1 justify-content-center" >
                        Distance 
                        <span 
                            onClick={() => handleSort("dist")}
                            style={{cursor: 'pointer'}}
                        >
                            <MDBIcon fas  icon={sort == sortOptions.dist.asc || sort == sortOptions.dist.desc? sort == sortOptions.dist.asc? "sort-up": "sort-down" : "sort-down"} />
                        </span>

                    </div>
                </th>
                <th className='d-none d-md-table-cell text-center ' style={{ position: "sticky", top: '-2px', backgroundColor: "white"}}>City</th>
                <th className='d-none d-md-table-cell text-center ' style={{ position: "sticky", top: '-2px', backgroundColor: "white"}}>State</th>
                <th className='d-none d-md-table-cell text-center ' style={{ position: "sticky", top: '-2px', backgroundColor: "white"}}>Zipcode</th>
                {/*<th className='d-table-cell d-md-none text-center ' style={{ position: "sticky", top: '-2px', backgroundColor: "white"}}>Location</th> */}
                <th  className='text-center' style={{ position: "sticky", top: '-2px', backgroundColor: "white", zIndex: 1, color: "white"}}>Show</th>

            </tr>

        </thead>
        <tbody className='pb-2' style={{width: '100%'}}>
            {leads.length > 0 ? leads.map((lead) => (
            <tr  key={ lead.leadID } style={{backgroundColor: lead.disable? "#E6E8E8": ""}}>
                <td className='px-1 text-center' style={{borderBottom: 0, verticalAlign: 'middle'}}>
                    <LeadsIcon 
                        width={lead.disable? "100px": "50px"}
                        height={lead.disable? "80px": "50px"}
                        css={lead.disable?'tableLeadboard-disabled': 'tableLeadboard'}
                        icon={lead.disable? "BookedIcon": lead.iconName}

                    />
                </td>
                <td style={{borderBottom: 0, verticalAlign: 'middle'}} className=' text-center '>
                    <div className='d-flex justify-content-center align-items-center'>
                        {lead.serviceDetail}
                    </div>
                </td>
                <td style={{borderBottom: 0, verticalAlign: 'middle'}} className='d-none d-md-table-cell text-center '>
                    <div  className='d-flex justify-content-center align-items-center'>
                        {   lead.disable?
                            ""
                            :
                            lead.leadDate == 1? `${lead.leadDate} Day` :  
                            lead.leadDate == 0? <>{`${lead.leadDate} Days`}<span role="img" aria-label="fire">🔥</span></>
                            :`${lead.leadDate} Days`
                        }
                        
                    </div>
                </td>
                <td style={{borderBottom: 0, verticalAlign: 'middle'}} className='d-none d-md-table-cell text-center '>
                    <div className='d-flex justify-content-center align-items-center'>
                        {`${parseInt(lead.distance)} mi`}
                    </div>
                </td>
                <td style={{borderBottom: 0, verticalAlign: 'middle'}} className='d-none d-md-table-cell text-center '>
                    <div className='d-flex justify-content-center align-items-center'>
                        {lead.city}
                    </div>
                </td>
                <td style={{borderBottom: 0, verticalAlign: 'middle'}} className='d-none d-md-table-cell text-center '>
                    <div className='d-flex justify-content-center align-items-center'>
                        {lead.usState}

                    </div>
                </td>
                <td style={{borderBottom: 0, verticalAlign: 'middle'}} className='d-none d-md-table-cell text-center '>
                    <div className='d-flex justify-content-center align-items-center'>
                        {lead.zipcode}
                    </div>
                </td>
                {/* <td style={{borderBottom: 0, verticalAlign: 'middle'}} className='d-table-cell d-md-none text-center '>
                    <div className='d-flex justify-content-center align-items-center'>
                        {`${lead.city}-${lead.usState}-${lead.zipcode}`}
                    </div>
                </td> */}
                <td style={{paddingBlock: "0.83rem", borderBottom: 0, verticalAlign: 'middle'}} className='text-center'>
                    <div className='d-flex justify-content-center align-items-center'>
                        <Button 
                            variant="dark" 
                            onClick={() => handleDetails(lead)}
                            size="sm"
                            disabled={lead.disable}
                            className={styles['disabled-button']}
                            >
                                {lead.disable? "Disabled" :"Details"}
                        </Button>
                    </div>
                </td>
            </tr>
            )) : null}
            {!leads.length?
            localStorage.getItem("token_user")? 
            <td align="center" colSpan="100%">It looks like all the leads in your area have already been booked. Don't worry, we will notify you as soon as new leads come along.</td>
            :
            <td align="center" colSpan="100%">
                <div className="d-flex flex-column gap-3 align-items-center justify-content-center">
                    <span>It looks like all the leads in your area have already been booked. Sign up and we will notify you as soon as new leads come along.</span>
                    <MDBBtn size="sm" style={{width: "50%"}} color="dark" onClick={() => navigate("/register1")}> Sign Up</MDBBtn>
                </div>
            </td>
            :
            null
        }
        </tbody>                        
    </Table>
    )
}

export default LeadBoardTable;