import React, { useState, useEffect } from "react";
import { BaseFront } from "../../config";
import { useParams, useNavigate, Link } from "react-router-dom";
import httpClient from "../../config/httpClient";
import LeadsLoader from "../../components/LeadsLoader";
import { BaseApi } from "../../config";
import { Modal, Card } from "react-bootstrap";
import { delay } from "lodash";
export const LeadNotificationScreen = () => {
    const [show, setShow] = useState(false);
    const [validLead, setValidLead] = useState(true);
    const params = useParams();
    const navigate = useNavigate();
    useEffect(() => {
        setLead()
    }, [])
    
    const setLead = async () => {
        const leadID = params.link
        const already_claimed= await (await httpClient.get(`${BaseApi}/leads/claimed_status/?leadID=${leadID}`)).data.already_claimed;
        setValidLead(!already_claimed)
        setShow(true)
        delay(() => {
            if (localStorage.getItem("token_user")) {
                navigate(`/leadboard/?leadID=${leadID}`)
                // window.location.href=`${BaseFront}/leadboard/?leadID=${leadID}`
                return
            }
            if (!already_claimed) localStorage.setItem("notification_lead", leadID)
            navigate(`/login`)
            // window.location.href=`${BaseFront}/login/`
        }, 4000)
    }
    
    

    return <div style={{height: "100vh"}}>
        <LeadsLoader />
        <Modal show={show}>
        <Modal.Body>
                <Card>
                  <Card.Body>
                  <div className='d-flex justify-content-center align-items-center flex-column'>
                      <span className='leadboard-success-icon' style={{color: !validLead? "red": ""}}><i className={validLead? 'fas fa-check-circle' : 'fas fa-times-circle'}></i></span>
                      <span className='lead-board-success-description text-center'>
                        {validLead? 
                            localStorage.getItem("token_user")?
                            "The lead is still available, you will be redirected to the Lead Board"
                            :
                            "Please login so you can claim the lead"
                            :
                            "Sorry the lead is already claimed"
                        }
                      </span>
                      <span>Please <Link to={localStorage.getItem("token_user")? validLead?  `/leadboard/?leadID=${params.link}` : "/leadboard" : "/login"}>click here</Link> if you are not redirected automatically</span>
                  </div>

                  </Card.Body>
                </Card>
            </Modal.Body>
        </Modal>
    </div>
}

export default LeadNotificationScreen;