import { Navigate} from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import React from 'react';


export const RequireAuth = ({ children }) => {
    const auth = useAuth()

    if (!localStorage.getItem("token_user")) {
        return <Navigate to='/login' />
    }

    return children
}