import React from "react";
import { Routes, Route } from "react-router-dom";
import LoginPage from "../../screens/Login";
import LeadBoard from "../../screens/LeadBoard";
import MyLeads from "../../screens/MyLeads";
import LeadNotificationScreen from "../../screens/LeadNotificationScreen";
import UserRegistration from "../../screens/UserRegistration";
import CompanyRegistration from "../../screens/CompanyRegistration";
import TermsAndConditions from "../../screens/TermsAndConditions";
import  ConfirmPaymentPage from "../../screens/ConfirmPaymentPage";
import UnscribeEmail from "../../screens/UnscribeEmail";
import UserPage from "../../screens/UserPage";
import { AuthProvider } from "../../contexts/AuthContext";
import { RequireAuth } from "../../middleware/RequireAuth";
import { WithNav, WithoutNav } from "../../components/NavPage";
import { RecoverPassword } from "../../screens/RecoverPasword";
export const routes = [
    {
        path: '/myleads',
        name: 'My Leads',
        element: <RequireAuth><MyLeads/></RequireAuth>,
        withNav: true,
        navItem: true
    },
    /* {
        path: '/closedleads',
        name: 'Closed Leads',
        element: <RequireAuth><LeadBoard/></RequireAuth>,
        withNav: true,
        navItem: true
    }, */
    {
        path: '/user',
        name: 'leadBoard',
        element: <RequireAuth><LeadBoard/></RequireAuth>,
        withNav: true,
    },
]

export const Routing = () => {
    return(
        <AuthProvider>
            <Routes>
                <Route element={<WithNav />}>
                    <Route path="/" element={<LeadBoard/>} />
                <Route path="/leadboard" element={<LeadBoard/>} />  
                    <Route path="/myleads" element={<RequireAuth><MyLeads/></RequireAuth>} />
                    <Route path="/user" element={<RequireAuth><UserPage/></RequireAuth>} />
                </Route>
                <Route element={<WithoutNav />}>
                    <Route path="/leadboard/account/payments/setup-complete" element={<ConfirmPaymentPage/>} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/register1" element={<UserRegistration />} />
                    <Route path="/register2" element={<CompanyRegistration/>} />
                    <Route path="/register3" element={<TermsAndConditions/>} />
                    <Route path="/recover_password" element={<RecoverPassword/>} />  
                    <Route path="/recover_password_link/:link" element={<RecoverPassword />} />
                    <Route path="/unscribe_email/:link" element={<UnscribeEmail />} />
                    <Route path="/lead_notification/:link" element={<LeadNotificationScreen />} />
                </Route>
            </Routes>
        </AuthProvider>
    )
}

export default Routing;