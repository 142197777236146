import React, { useEffect, useState } from 'react';
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
}
from 'mdb-react-ui-kit';
import { TermsAndConditionsHtml } from '../../components/TermsAndConditionsHtml';
import { useNavigate } from 'react-router-dom';
import httpClient from '../../config/httpClient';
import { BaseApi } from '../../config';
import logo from '../../assets/images/logo.svg';
import Card from 'react-bootstrap/Card';
import cardImage from '../../assets/images/contractorMixer.jpg'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useAuth } from '../../hooks/useAuth';
import { validateUserRegistrations } from '../../helpers/validationHelpers';
import './TermsAndConditions.css';

export const TermsAndConditions = () => {
    const [loading, setLoading] = useState(false)
    const auth = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
      (async () => {
        const resp = await httpClient.get(`${BaseApi}/@me`)
        const valid = validateUserRegistrations(resp.data)
        if (!valid.companyRegistered) navigate('/register2')
      })()
    }, [])

    const acceptTerms = async () => {
        try {
            setLoading(true);
            await httpClient.post(`${BaseApi}/register/accept/`, { });

            const resp = await httpClient.get(`${BaseApi}/@me`)
            auth.login(resp.data);
            localStorage.setItem("user", JSON.stringify(resp.data));
            
            validateUserRegistrations(resp.data)
            
            navigate('/leadboard', {replace: true})
            setLoading(false)
        } catch (error) {
            if (error.response.status === 401) {
                alert("Invalid Session");
                setLoading(false)
            }
        }
    };

    return(
      <MDBContainer className="my-5">

      <MDBCard>
        <MDBRow className='g-0'>
  
          <MDBCol md='6'>
            <MDBCardImage src={cardImage} alt="login form" className='d-md-block d-none rounded-start w-100'/>
          </MDBCol>
  
          <MDBCol md='6'>
            <MDBCardBody className='d-flex flex-column'>
              <div>
                <Breadcrumb>
                  <Breadcrumb.Item active> User Information </Breadcrumb.Item>
                  <Breadcrumb.Item active> Company Information </Breadcrumb.Item>
                  <Breadcrumb.Item> Terms and Conditions </Breadcrumb.Item>
                </Breadcrumb>
              </div>  
  
              <div className='d-flex flex-row mt-2'>
                <img
                  src={logo}
                  width = "5000"
                  height ="100"
                  className="d-inline-block align-top"
                  alt="Lead Depot Logo"
                />
              </div>

              <br/>

              <Form>
                <Card
                    bg={'Light'}
                    style={{height:'50vh', 
                            overflowY : 'scroll',
                            borderColor : '#000'
                            }}
                >
                    <Card.Body>
                    <TermsAndConditionsHtml />
                  
                    </Card.Body> 
                </Card>

                <br/>

                <Form.Group>
                  <div className="d-grid gap-2">
                    <Button disabled={loading} variant="dark" size="lg" onClick={acceptTerms}>
                      Accept
                    </Button>
                  </div>
                </Form.Group>

              </Form>
  
            </MDBCardBody>
          </MDBCol>
  
        </MDBRow>
      </MDBCard>
  
    </MDBContainer>
    )
};

export default TermsAndConditions;