import React, {useState} from "react";
import { Modal } from "react-bootstrap";
import "./HowItWorks.css"

export const HowItWorks = () => {
    const [show, setShow] = useState(false);
    
    const handleShow = () =>  setShow(true);
    const handleClose = () => {
        setShow(false);
    }

    return (<>
        <div onClick={() => handleShow()} className="nav-link">
            How It Works
        </div>
        <Modal show={show} onHide={() => handleClose()}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body >
                <div className="video-container">

            <iframe width="560" height="315" src="https://www.youtube.com/embed/KFsfgIdTsrc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
            </Modal.Body>
        </Modal>
    </>)
}

export default HowItWorks;