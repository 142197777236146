import React from "react"
import  Excel from "../../assets/icons/excel.svg"
import { getLeadsCsv } from "../../api/leadsApi"

export const DownloadMyLeads = ({closedLeads}) => {

    const downloadLeads = async () => {
        const type = closedLeads ? "closed_leads" : "my_leads"
        const response = await getLeadsCsv(type)
        const url = window.URL.createObjectURL(new Blob([response])) 
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', "my_leads.csv")
        document.body.appendChild(link)
        link.click()
    }

    return  <span onClick={() => downloadLeads() } style={{cursor:"pointer"}}><img src={Excel} width="48px" height="48px" /></span>
}

export default DownloadMyLeads;