import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import {useStripe, useElements, PaymentElement, CardElement} from '@stripe/react-stripe-js';
import { BaseFront } from '../../config';

export const SetupForm = ({priceID}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;
    localStorage.setItem('priceIDforCardRecentConfirmed', priceID )
    const {error} = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${BaseFront}/leadboard/account/payments/setup-complete`,
      },
    });
    if (error) {
      localStorage.removeItem("leadClaimedToSuccess")
      localStorage.removeItem("MyLead")
      localStorage.removeItem('priceIDforCardRecentConfirmed')
      // window.location.href=`${BaseFront}/leadboard`
    };
  }

  return (
    <form onSubmit={e => handleSubmit(e)}>
    <PaymentElement 
      options={{}}
    />
      <br/>
      <div className= "d-grid gap-2">
          <Button type="submit" disabled={!stripe} variant="dark" size = "lg">
              Submit
          </Button>
          {errorMessage && <div>{errorMessage}</div>}
      </div>
    </form>
  );
};

export default SetupForm;