import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { MDBBtn } from "mdb-react-ui-kit";
import { contactSupport } from "../../api/userApi";

export const SupportContact = () => {
    
    const [show, setShow] = useState(false)
    const [topic, setTopic] = useState("");
    const [description, setDescription] = useState("")
    const [invalidDescription, setInvalidDescription] = useState(false);
    const [loading, setLoading] = useState(false);
    
    const handleTopic = (e) => {
        if (e.target.value.length > 100) return
        setTopic(e.target.value)
    }
    
    const handleSupportRequest = async () => {
        if (description.length < 100) return setInvalidDescription(true);
        try {
            setLoading(true)
            const data = {topic, description}
            const res = await contactSupport(data);
            handleClose()
            setLoading(false)
        } catch (e) {
            console.log(e)
            setLoading(false)
        }
        //if (res.message) window.location.href = ""
    }

    const cleanInputs = () => {
        setTopic("");
        setDescription("")
        setInvalidDescription(false)
    }

    const handleClose = () => {
        setShow(false);
        cleanInputs()
    }
 
    useEffect(() => {
        if (invalidDescription && description.length >=100) setInvalidDescription(false)
    }, [description])


    return (
        <>
            <div className="nav-item" onClick={() => setShow(true)}>
                <span className="nav-link active" style={{cursor: "pointer"}}>Contact Support</span>
            </div>
            <Modal show={show} onHide={() => handleClose()}>
                <Modal.Body>
                    <div className="container p-4">
                        <div className="row mb-4">
                            <div className="col-4">
                                <span style={{fontWeight: 500}}>Subject</span>
                            </div>
                            <div className="col-8">
                                <input className="form-control" value={topic} onChange={handleTopic} />
                                <span style={{fontSize:"12px"}}>Please enter the reason you are contacting us about(eg. signup question) Maximum 100 characters.</span>
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col-4">
                                <span style={{fontWeight: 500}}>What happened?*</span>
                            </div>
                            <div className="col-8">
                                <textarea onChange={(e) => setDescription(e.target.value)} value={description} rows={4} className="form-control" style={{borderColor: invalidDescription? "red": ""}} />
                                <span style={{fontSize: "10px", float: "right"}}>{`${description.length} characters`}</span>
                                <span style={{fontSize:"12px"}}>Please describe the problem and include any error messages. Minimun 100 characters.</span>
                            </div>
                        </div>
                        <MDBBtn disabled={loading} onClick={() => handleSupportRequest()} style={{float: "right"}} color="dark">Send Support Request</MDBBtn>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default SupportContact;