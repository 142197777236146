import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { StripeKey } from '../../config';
import PaymentStatus from '../../components/PaymentStatus';
const stripePromise = loadStripe(StripeKey);

export const ConfirmPaymentPage = () => {
    return (
        <Elements stripe={stripePromise}>
            <PaymentStatus />
        </Elements>
    )
    
}

export default ConfirmPaymentPage;