import React, { useEffect, useState } from 'react';
import {
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBInput,
}
from 'mdb-react-ui-kit';
import { useNavigate } from 'react-router-dom';
import httpClient from '../../config/httpClient';
import { BaseApi, GoogleMapApiKey } from '../../config';
import logo from '../../assets/images/logo.svg';
import cardImage from '../../assets/images/contractorMixer.jpg'
import { Modal, Row ,Col , Form, Button, Breadcrumb } from 'react-bootstrap';
import { useJsApiLoader, Autocomplete} from '@react-google-maps/api';
import { emptyString } from '../../helpers/validationHelpers';
import Skeleton from 'react-loading-skeleton'
import { surveyRegisterOptions } from '../../helpers/constantData';
import { validateUserRegistrations } from '../../helpers/validationHelpers';

export const CompanyRegistration = () => {
    const [show, setShow] = useState(false)
    const [dbaName, setDBA] = useState("");
    const [invalidDbaName, setInvalidDbaName] = useState(false)
    const [streetAddress, setAddress] = useState("");
    const [invalidStreetAddress, setInvalidStreetAddress] = useState(false)
    const [employeeCount, setCount] = useState("");
    const [invalidEmployeeCount, setInvalidEmployeeCount] = useState(false);
    const [companyAge, setAge] = useState("");
    const [invalidCompanyAge, setInvalidCompanyAge] = useState(false)
    const [autocomplete, setAutocomplete] = useState(null)
    const [survey, setSurvey] = useState("bing")
    const [surveyDescription, setSurveyDescription] = useState("")
    const [loadingRegister, setLoadingRegister] = useState(false)

    const navigate = useNavigate()

    const { isLoaded } = useJsApiLoader({
      googleMapsApiKey: GoogleMapApiKey,
      libraries: ['places']
  }) 


    useEffect(() => {
      httpClient.defaults.headers.common["x-access-tokens"] = localStorage.getItem("token_user");
      handleValidationUserRegisters()
    }
    , [])

    const handleValidationUserRegisters = async () => {
      const resp = await httpClient.get(`${BaseApi}/@me`)
      const valid = validateUserRegistrations(resp.data)
      if (valid.companyRegistered && !valid.acceptedTNC) navigate('/register3')
      if (valid.companyRegistered && valid.acceptedTNC) navigate('/leadboard')
    }

    const handleHide = () => {
      setInvalidDbaName(false)
      setInvalidStreetAddress(false)
      setInvalidEmployeeCount(false)
      setInvalidCompanyAge(false)
      setShow(false);
    }

    const setInvalidations = () => {
      if (emptyString(dbaName) || emptyString(streetAddress) || emptyString(employeeCount) || emptyString(companyAge)) {
        if(emptyString(dbaName)) setInvalidDbaName(true)
        if(emptyString(streetAddress)) setInvalidStreetAddress(true)
        if(emptyString(employeeCount)) setInvalidEmployeeCount(true)
        if(emptyString(companyAge)) setInvalidCompanyAge(true)
        setShow(true)
        return true
        }
        return false
    }

    const registerCompany = async () => {
      if (setInvalidations()) return
      try {
        setLoadingRegister(true)
        const body = {
          dbaName,
          streetAddress,
          employeeCount,
          companyAge,
          surveyOption: survey,
        }
        if (survey === "other") body.surveyOptionDescription = surveyDescription
        await httpClient.post(`${BaseApi}/register/company/`, body );
        handleValidationUserRegisters();
      } catch (error) {
        if (error.response.status === 401 ) {
           alert("Invalid credentials");
        }
        if (error.response.status === 409) handleValidationUserRegisters();
        setLoadingRegister(false);
      }
    };
    
    const loadAutocomplete = (autocmplete) => {
      setAutocomplete(autocmplete)
    }

    const onPlaceChanged = () => {
      if (autocomplete) setAddress(autocomplete.getPlace().formatted_address)
    }

      if (!isLoaded) {
        return (
            <div className='container-fluid'>
                <Skeleton height={60}/>
                <div className='row'>
                    <div className='col'>
                        <Skeleton height={"100vh"} />
                    </div>
                    <div className='col'>
                        <Skeleton height={"100vh"} />
                    </div>
                </div>
            </div>
        );
    }

  
      return(
        <MDBContainer className="my-5">
  
        <MDBCard>
          <MDBRow className='g-0'>
    
            <MDBCol md='6'>
              <MDBCardImage src={cardImage} alt="login form" className='d-md-block d-none rounded-start w-100'/>
            </MDBCol>
    
            <MDBCol md='6'>
              <MDBCardBody className='d-flex flex-column'>
                <div>
                  <Breadcrumb>
                    <Breadcrumb.Item active> User Information </Breadcrumb.Item>
                    <Breadcrumb.Item> Company Information </Breadcrumb.Item>
                    <Breadcrumb.Item active> Terms and Conditions </Breadcrumb.Item>
                  </Breadcrumb>
                </div>  
    
                <div className='d-flex flex-row mt-2'>
                  <img
                    src={logo}
                    width = "5000"
                    height ="100"
                    className="d-inline-block align-top"
                    alt="Lead Depot Logo"
                  />
                </div>
  
                <br/><br/>
  
                <Form>
                  <Form.Group>
                    <MDBInput wrapperClass='mb-4' label='Company Name' id='formControlDBA' type='text' size="lg" onChange={e => setDBA(e.target.value)}/>
                  </Form.Group>
  
  
                  <Form.Group>
                    <Autocomplete onLoad={loadAutocomplete} onPlaceChanged={onPlaceChanged} restrictions={{"country": "US"}}>
                      <MDBInput wrapperClass='mb-4' label='Company Address' id='formControlAddress' type='text' size="lg" />
                    </Autocomplete>
                  </Form.Group>
  
                  <Form.Group>
                    <Row>
                      <Col><MDBInput wrapperClass='mb-4' label='Company Employees' id='formControlSize' type='text' size="lg" onChange={e => setCount(e.target.value)}/></Col>
                      <Col><MDBInput wrapperClass='mb-4' label='Years In Business' id='formControlAge' type='text' size="lg" onChange={e => setAge(e.target.value)} /></Col>
                    </Row>
                  </Form.Group>

                  <Form.Group>
                    <Row className='mb-4'>
                      <Col>
                        <label className="form-label">How did you hear about us?</label>
                        <select value={survey} onChange={(e) => setSurvey(e.target.value)} className='form-select'>
                          {
                            surveyRegisterOptions.map((option) => (
                              <option value={option.value}>
                                {option.label}
                              </option>
                            ))
                          }
                        </select>
                        {survey === "other"? 
                          <input placeholder='Other...' className='form-control mt-2' value={surveyDescription} onChange={(e) => setSurveyDescription(e.target.value)} type="text" />
                          :
                          null
                        }
                      </Col>
                    </Row>
                  </Form.Group>
  
                  <Form.Group>
                    <div className="d-grid gap-2">
                      <Button variant="dark" size="lg" onClick={registerCompany} disable={loadingRegister}>
                        Continue
                      </Button>
                    </div>
                  </Form.Group>
  
                </Form>
  
                <br/>
  
                <div className='d-flex flex-row justify-content-start'>
                  <a href="https://leaddepot.com/terms-and-conditions/" className="small text-muted me-1">Terms of use.</a>
                  <a href="https://leaddepot.com/privacy-policy/" className="small text-muted">Privacy policy</a>
                </div>
    
              </MDBCardBody>
            </MDBCol>
    
          </MDBRow>
        </MDBCard>
        <Modal show={show} onHide={() => handleHide()}>
            <Modal.Header>
                Invalid Inputs
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex flex-column gap-2'>
                    { invalidDbaName?  <div class="alert alert-danger" role="alert">Please provide a company name</div> : null}
                    { invalidStreetAddress? <div class="alert alert-danger" role="alert">Please provide a company address</div> : null}
                    { invalidEmployeeCount? <div class="alert alert-danger" role="alert"> Please provide the company employees amount</div> : null}
                    { invalidCompanyAge? <div class="alert alert-danger" role="alert">Please provide the years In business</div> : null}
                </div>
            </Modal.Body>
        </Modal>
      </MDBContainer>
    )
}

export default CompanyRegistration;