import React, { useState, useEffect } from "react";
import NewPasswordInput from "./NewPasswordInput";
import InvalidToken from "./InvalidToken";
import { recoverPasswordLinkValid } from "../../api/userApi";
import LeadsLoader from "../LeadsLoader";
import { useNavigate } from "react-router-dom";

export const RecoverPasswordNew = ({token}) => {
    const [valid, setValid] = useState(false);
    const [loadValid, setLoadValid] = useState(true);
    const navigate = useNavigate()
    useEffect(() => {
        getValidStatus();
    }, [])

    const getValidStatus = async () => {
        const response = await recoverPasswordLinkValid({recover_link: token})
        setValid(response.valid_token);
        setLoadValid(false);
        if (!response.valid_token) navigate("/login")
    } 

    return (<>
        {
            loadValid?
            <LeadsLoader />
            :
            valid?
            <NewPasswordInput token={token}/>
            :
            <InvalidToken />
        }
    </>)
}

export default RecoverPasswordNew;