import React, {useState, createContext, useEffect} from 'react';
import httpClient from '../../config/httpClient';
import { BaseApi } from '../../config';
import { useNavigate } from 'react-router-dom';
import { decryptData } from '../../helpers/encrypt';
import { ToastContainer, toast } from 'react-toastify';
import { delay } from 'lodash';
import 'react-toastify/dist/ReactToastify.css';
export const AuthContext = createContext(null)

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const navigate = useNavigate()

    useEffect(() => { 
        if (!user)(async () => {
          try {
          const resp = await httpClient.get(`${BaseApi}/@me`)
          setUser(resp.data)
          if (localStorage.getItem('version')) {
            if (localStorage.getItem('version') !== resp.data.version) {
              localStorage.setItem('version', resp.data.version);
                caches.keys().then((names) => {
                names.forEach((name) => {
                  caches.delete(name);
                });
                window.location.reload(true);
              });
            }
          }
          else {
            localStorage.setItem('version', resp.data.version)
          }
          if (
            localStorage.getItem('acceptedTNC') 
            &&  localStorage.getItem('companyRegistered') 
            &&  localStorage.getItem('token_user')
          ) {
            const acceptedTNC = decryptData(localStorage.getItem('acceptedTNC')).split('/')
            const companyRegistered = decryptData(localStorage.getItem('companyRegistered')).split('/')
            console.log(acceptedTNC)
            console.log(companyRegistered)
            if (
              companyRegistered.length != 2 
              || resp.data.id !== companyRegistered[companyRegistered.length - 1]
            ) return invalidSession('company')
            if (
              acceptedTNC.length != 2 
              || resp.data.id !== acceptedTNC[acceptedTNC.length - 1]
            ) return invalidSession('tnc')
          } else {
            return invalidSession('invalid session');
          }
           // navigate('/leadboard', {replace: true})
          } catch (error) {
            console.log("Not authenticated");
          }
        })();
      }, []);

    const login = (response) => {
        setUser(response)
    }

    const invalidSession = (invalidType) => {
      if (invalidType === 'company' || invalidType === 'tnc') {
        console.log('invalid')
        const url = invalidType === 'company'? '/register2' : '/register3'
        toast('Thank you for your interest in Lead Depot! To continue please complete your registration. Redirecting you now...', {type: 'warning'})
        return delay(() => {navigate(url)}, 4000)
        
      } else {
        localStorage.removetItem('acceptedTNC') 
        localStorage.removeItem('companyRegistered')
        localStorage.removeItem("token_user");
        localStorage.removeItem("user");    
        localStorage.removeItem("verticals")
        localStorage.removeItem("radius")
        localStorage.removeItem("zip")
        localStorage.removeItem("leadClaimed")
        toast('Thank you for your interest in Lead Depot! To continue please complete your registration. Redirecting you now...', {type: 'warning'})
        delay(() => {navigate('/login', {replace: true})}, 4000)
      }
    }

    const logout = () => {
        setUser(null)
    }

    return(
        <AuthContext.Provider value={{user,login,logout}}>
            <ToastContainer />
            {children}
        </AuthContext.Provider>
    )
}