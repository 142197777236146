import React, {useState, useEffect} from 'react';
import {useStripe} from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';

const PaymentStatus = () => {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
useEffect(() => {
    if (!stripe) {
      return;
    }
    handleStatus();
      }, [stripe]);

const handleStatus = async () => {
    const clientSecret = new URLSearchParams(window.location.search).get(
        'setup_intent_client_secret'
      );
  
      console.log(clientSecret);
  
      // Retrieve the SetupIntent
      const setupIntent = await stripe.retrieveSetupIntent(clientSecret)
      switch (setupIntent.setupIntent.status) {
        case 'succeeded':
            setMessage('Success! Your payment method has been saved.');
            break;

        case 'processing':
            setMessage("Processing payment details. We'll update you when processing is complete.");
            break;

        case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            setMessage('Failed to process payment details. Please try another payment method.');
            break;
        }
        navigate('/leadboard', {replace: true})
}

  return message
};

export default PaymentStatus;
