import React, {useState, useEffect} from "react";
import { MDBCard, MDBContainer, MDBInput, MDBBtn} from "mdb-react-ui-kit";
import { Card, Modal } from "react-bootstrap";
import { BaseFront } from "../../config";
import { delay } from "lodash";
import { isValidEmail, emptyString } from "../../helpers/validationHelpers";
import { recoverPasswordEmail } from "../../api/userApi";
import logo from '../../assets/images/logo.svg';

export const RecoverPasswordEmailInput = () => {
    const [email, setEmail] = useState('');
    const [show, setShow] = useState(false);
    const [loadEmail, setLoadEmail] = useState(false);
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [unexistentMail, setUnexistentMail] = useState(false)
    
    const handleRecoverPassword = async () => {
        if (emptyString(email) || !isValidEmail(email)) return setInvalidEmail(true); 
        setLoadEmail(true)
        const response = await recoverPasswordEmail({email})
        setLoadEmail(false)
        if (response.error) return setUnexistentMail(true)
        setShow(true);
        delay(()=> {
          window.location.href=`${BaseFront}/login/`
        }, 3000)
    };

    useEffect(() => {
        setInvalidEmail(false);
        setUnexistentMail(false);
    }, [email])
    
    return (
        <MDBContainer className="my-5">
            <MDBCard className="p-5"> 
            <div className='d-flex flex-column mt-2'>
              <img
                src={logo}
                width = "100%"
                className="d-inline-block align-top login-logo-size"
                alt="Lead Depot Logo"
              />
               <h5 className="fw-normal my-2 pb-md-3" style={{letterSpacing: '1px'}}>Password Reset</h5>
              {
                invalidEmail || unexistentMail? 
                <form className="needs-validation was-validated">
                <div>
                  <div className='form-outline'>
                    <input 
                      id="invalid" 
                      className='form-control active form-control-lg' 
                      style={{border: "1.5px solid #dc3545"}}
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      
                    />
                    <label className="form-label" for="invalid" style={{color:"#dc3545", backgroundColor: "white", paddingInline: "4px"}}>Email</label>
                    <div style={{ display: 'block', position: 'relative', zIndex: 2}} className="invalid-feedback">
                      {invalidEmail? "This email is not valid": "This email doesn't exist"} 
                    </div>

                  </div>
                </div>

                </form>
                :
                <MDBInput value={email} wrapperClass='mb-4'  label='Email address' id='formControlUN' type='email' size="lg" onChange={e => setEmail(e.target.value)}/>
              }
              <MDBBtn color="dark" disabled={loadEmail} onClick={() => handleRecoverPassword()}>
                Reset Password
              </MDBBtn>
            </div>
            </MDBCard>
            <Modal show={show}>
            <Modal.Body>
                <Card>
                  <Card.Body>

                  <div className='d-flex justify-content-center align-items-center flex-column'>

                      <span className='leadboard-success-icon'><i className='fas fa-check-circle'></i></span>
                      <span className='lead-board-success-description'>Thank you, further instructions will be sent via email if an account exists with the provided address . Please <a href={`${BaseFront}/login`}>click here</a> if you are not redirected automatically</span>
                  </div>

                  </Card.Body>
                </Card>
            </Modal.Body> 
            </Modal>
        </MDBContainer>
    )
}
export default RecoverPasswordEmailInput;