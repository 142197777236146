import httpClient from "../config/httpClient";
import { BaseApi } from "../config";

export const getUserInfo = async () => {
    try {
        const response = await httpClient.get(`${BaseApi}/@me`)
        return response.data;
    } catch (e) {
        console.error(e)
        return {
            error: "Error getting user info"
        }
    }
}

export const editUserInfo = async (body) => {
    try {
        const response = await httpClient.put(`${BaseApi}/user/`, body)
        return response.data;
    } catch (e) {
        console.error(e)
        return {
            error: "Error editing user info"
        }
    }
}

export const getUserCompanyInfo = async () => {
    try {
        const response = await httpClient.get(`${BaseApi}/register/company/`)
        return response.data;
    } catch (e) {
        console.error(e)
        return {
            error: "Error editing user info"
        }
    }
}

export const updateUserCompanyInfo = async (body) => {
    try {
        const response = await httpClient.put(`${BaseApi}/register/company/`, body)
        return response.data;
    } catch (e) {
        console.error(e)
        return {
            error: "Error editing user info"
        }
    }
}
export const contactSupport = async (body) => {
    try {
        const response = await httpClient.post(`${BaseApi}/contactsupport/`, body)
        return response.data;
    } catch (e) {
        console.error(e)
        return {
            error: "Error sending contact support"
        }
    }
}

export const recoverPasswordEmail = async (body) => {
    try {
        const response = await httpClient.post(`${BaseApi}/recover_password/set_link/`, body)
        return response.data;
    } catch (e) {
        console.error(e)
        return {
            error: "Error recovering password email"
        }
    }
}

export const authRecoverPasswordLink = async (body) => {
    try {
        const response = await httpClient.post(`${BaseApi}/reset_password/`, body)
        return response.data;
    } catch (e) {
        console.error(e)
        return {
            error: "Error confirming password token"
        }
    }
}
export const recoverPasswordLinkValid = async (body) => {
    try {
        const response = await httpClient.post(`${BaseApi}/recover_password/token_valid_status/`, body)
        return response.data;
    } catch (e) {
        console.error(e)
        return {
            error: "Error getting the token valid status"
        }
    }
}

