import React, {useState, useEffect} from "react";
import { MDBCard, MDBContainer, MDBInput, MDBBtn} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import { isValidEmail, emptyString } from "../../helpers/validationHelpers";
import { authRecoverPasswordLink } from "../../api/userApi";
import logo from '../../assets/images/logo.svg';


export const NewPasswordInput = ({token}) => {
    const [password, setPassword] = useState('');
    const [invalidPassword, setInvalidPassword] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState('')
    const [loadNewPassword, setLoadNewPassword] = useState(false);
    const navigate = useNavigate()

    const handleRecoverPassword = async () => {
        if (password !== confirmPassword || emptyString(password)) return setInvalidPassword(true)
        const response = await authRecoverPasswordLink({password, token})
        navigate("/login")
    };

    return (
        <MDBContainer className="my-5">
            <MDBCard className="p-5"> 
            <div className='d-flex flex-column mt-2'>
              <img
                src={logo}
                width = "100%"
                className="d-inline-block align-top login-logo-size"
                alt="Lead Depot Logo"
              />
               <h5 className="fw-normal my-2 pb-md-3" style={{letterSpacing: '1px'}}>Reset your password</h5>
               { invalidPassword?
                <form className="needs-validation was-validated">
                <div>
                  <div className='form-outline'>
                    <input 
                      id="invalid" 
                      className='form-control active form-control-lg' 
                      style={{border: "1.5px solid #dc3545"}}
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      type="password"
                    />
                    <label className="form-label" for="invalid" style={{color:"#dc3545", backgroundColor: "white", paddingInline: "4px"}}>Password </label>
                    <div style={{ display: 'block', position: 'relative'}} className="invalid-feedback">
                      Password doesn't match with this account
                    </div>

                  </div>
                </div>

                </form>
                :
                <MDBInput value={password} wrapperClass='mb-4' label='Password' id='formControlPW' type='password' size="lg" onChange={e => setPassword(e.target.value)}/>
              }
               { invalidPassword?
                <form className="needs-validation was-validated">
                <div>
                  <div className='form-outline'>
                    <input 
                      id="invalid" 
                      className='form-control active form-control-lg' 
                      style={{border: "1.5px solid #dc3545"}}
                      value={confirmPassword}
                      onChange={e => setConfirmPassword(e.target.value)}
                      type="password"
                    />
                    <label className="form-label" for="invalid" style={{color:"#dc3545", backgroundColor: "white", paddingInline: "4px"}}>Confirm Password </label>
                    <div style={{ display: 'block', position: 'relative'}} className="invalid-feedback">
                      Password doesn't match with this account
                    </div>

                  </div>
                </div>

                </form>
                :
                <MDBInput value={confirmPassword} wrapperClass='mb-4' label='Confirm Password' id='formControlPW' type='password' size="lg" onChange={e => setConfirmPassword(e.target.value)}/>
              }
              <MDBBtn color="dark" disabled={loadNewPassword} onClick={() => handleRecoverPassword()}>
                reset password
              </MDBBtn>
            </div>
            </MDBCard>
        </MDBContainer>
    )
}

export default NewPasswordInput;